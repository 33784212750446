.sidebar {
    min-width: 345px;
    max-width: 345px;
    min-height: 800px;

    &-xdashboard {
        min-height: 66px;
    }
    position: relative;
    overflow: hidden;
    @media screen and (max-width: 1100px) {
        min-width: 320px;
        max-width: 320px;
    }
    @media screen and (max-width: 1023.8px) {
        display: none;
    }
    &-parent {
        min-width: 100%;
        left: 0;
        position: absolute;
        transition: all .6s ease;
        &.disable {
            left: -345px;
        }
    }
    &-lrm {
        min-width: 100%;
        transform: translate(345px);
        position: absolute;
        transition: all .6s ease;
        &.active {
            transform: translate(0)
        }
    }
    &-settings {
        min-width: 100%;
        transform: translate(345px);
        position: absolute;
        transition: all .6s ease;
        &.active {
            transform: translate(0)
        }
    }
    &-advertising-and-signage {
        min-width: 100%;
        transform: translate(345px);
        position: absolute;
        transition: all .6s ease;
        &.active {
            transform: translate(0)
        }
    }
    &-product-images {
        min-width: 100%;
        transform: translate(345px);
        position: absolute;
        transition: all .6s ease;
        &.active {
            transform: translate(0)
        } 
    }
    &-brand-assets {
        min-width: 100%;
        transform: translate(345px);
        position: absolute;
        transition: all .6s ease;
        &.active {
            transform: translate(0)
        } 
    }
    
    &-dashboard {
        min-width: 100%;
        transform: translate(345px);
        position: absolute;
        transition: all .6s ease;
        &.active {
            transform: translate(0)
        } 
    }
}