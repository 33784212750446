.lrm-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-gap: 20px;
    align-items: stretch;
    margin-top: 20px;
}

.lsm-popup__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-right: 20px;
    & > div {
        position: relative;
        width: 100%;
        overflow-y: auto;
        & > span {
            position: absolute;
            right: 3%;
            bottom: 10px;
            background: transparent;
            font-weight: 400;
            color: #171b1e;
        }
    }
    &__img {
        background: #e0e0e0;
        width: 100%;
        height: 100%;
    }
    img {
        width: 100%;
        height: 100%;
        max-height: 568px;
        object-fit: contain;
        background: #e0e0e0;
        box-sizing: border-box;
    }
    h5 {
        font-weight: 400;
        font-size: 26px;
        line-height: 35px;
        color: #171b1e;
        margin-bottom: 10px;
    }
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        color: #171b1e;
        margin-bottom: 4px;
    }
}

.lsm-popup {
    display: flex;
    height: 100%;
    align-items: flex-start;
    & .footer {
        
        display: flex;
        flex-direction: row-reverse;

        & button {
            font-family: "Mulish";
            font-weight: 700;
            font-size: 11px;
            height: 36px;
            cursor: pointer;
            color: #fff;
            background: #fa3535;
            border-color: #fa3535;
            border: 1px solid #a4a4a4;
            border-radius: 4px;
            transition: all 0.2s ease-in-out;
            width: 200px;
            &:hover {
                color: #fa3535;
                background: #fff;
                border-color: #fa3535;
            }
            &.full {
                flex: 1 auto;
                width: 100%;
            }
        }
    }
}

.lsm-popup__sidebar {
    margin-top: 9px;
    width: 564px;
    display: flex;
    
    
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: space-evenly;
    gap: 10px;
    overflow: hidden;
    &.front-view {
        flex-direction: column;
        flex-wrap: nowrap;
    }
    h5 {
        flex-grow: 4;
        font-family: "Mulish";
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 31px;
        text-align: left;
        color: #333333;
        padding-bottom: 2px;
    }
    & > button {
        flex: 1 1 0px;
        border: 1px solid #a4a4a4;
        border-radius: 4px;
        background: transparent;
        font-family: "Mulish";
        font-weight: 700;
        font-size: 11px;
        color: #4f4f4f;
        height: 36px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover {
            color: #fff;
            background: #fa3535;
            border-color: #fa3535;
        }
        &.full {
            flex: 1 auto;
            width: 100%;
        }
    }
    .btn__save {
        color: #fff;
        background: #fa3535;
        border-color: #fa3535;
        &:hover {
            background-color: #f00d0d;
            border: 1px solid #f00d0d;
        }
    }
    .btn__email {
        width: calc(50% - 23px);
    }
    &__title {
        h5 {
            font-family: "Mulish";
            font-weight: 700;
            font-size: 16px;
            color: #333333;
            padding-bottom: 7px;
        }
        border-bottom: 1px solid #b3b3b3;
        margin-bottom: 9px;
    }
    &__tag {
        h5 {
            font-weight: 700;
            font-size: 15px;
            line-height: 20px;
            color: #171b1e;
        }
        p {
            font-family: "Mulish";
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #4f4f4f;
            span {
                color: #fade57;
            }
        }
    }
    &__input-group {
        h5 {
            font-family: "Mulish";
            font-weight: 700;
            font-size: 16px;
            color: #333333;
            padding-bottom: 7px;
            border-bottom: 1px solid #b3b3b3;
        }
        div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 25px;
            &:first-child {
                margin-top: 0;
            }
            label {
                font-family: "Mulish";
                font-weight: 400;
                font-size: 12px;
                color: #4f4f4f;
                margin-bottom: 3px;
            }
            input {
                font-family: "Mulish";
                font-weight: 400;
                font-size: 14px;
                color: #333333;
                height: 36px;
                width: 100%;
                padding: 0 10px;
                box-sizing: border-box;
                border: 1px solid #b3b3b3;
                border-radius: 4px;
            }
        }
    }
    hr {
        height: 0;
        height: 1px;
        border: 0;
        background-color: #b3b3b3;
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

.lsm-popup__email {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h5 {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 31px;
        text-align: center;
        color: #333333;
    }
    p {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #828282;
        margin-bottom: 20px;
    }
    input {
        height: 50px;
        padding-left: 10px;
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        box-sizing: border-box;
        margin-bottom: 20px;
        color: #333;
        width: 300px;
        outline-color: #fa3535;
    }
    .btn-group {
        display: flex;
        button {
            &:first-of-type {
                width: 101px;
                height: 36px;
                font-family: "Mulish";
                font-style: normal;
                font-weight: 700;
                font-size: 11px;
                line-height: 14px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #2f80ed;
                background: transparent;
                border-radius: 4px;
                border: 0;
                text-align: center;
                justify-content: center;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                &:hover {
                    filter: contrast(1.2);
                }
            }
            &:last-of-type {
                width: 101px;
                height: 36px;
                font-family: "Mulish";
                font-style: normal;
                font-weight: 700;
                font-size: 11px;
                line-height: 14px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #fff;
                background: #fa3535;
                border-color: #fa3535;
                &:hover {
                    background-color: #f00d0d;
                    border: 1px solid #f00d0d;
                }
                border-radius: 4px;
                border: 0;
                text-align: center;
                justify-content: center;
                transition: all 0.3s ease-in-out;
                cursor: pointer;
                &:hover {
                    filter: contrast(1.2);
                }
            }
        }
    }
}

.lsm-popup__content--audio {
    width: 100%;
    min-height: 250px;
    audio {
        width: 100%;
    }
}

.lsm-popup__content--video {
    width: 100%;
    min-height: 250px;
    max-height: 300px;
    video {
        width: 100%;
    }
}

.lsm-sos {
    &__header {
        h5 {
            font-weight: 700;
            font-size: 15px;
            line-height: 20px;
            color: #171b1e;
        }
        p {
            font-family: "Mulish";
            font-weight: 400;
            font-size: 12px;
            color: #4f4f4f;
        }
    }
    &__content {
        background: #fff;
        border: 1px solid #b3b3b3;
        border-radius: 4px;
        padding: 8px 24px 11px 10px;
    }
    &__inputs {
        & > div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            &:first-child {
                margin-bottom: 17px;
            }
            label {
                font-family: "Mulish";
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #4f4f4f;
                margin-bottom: 3px;
            }
            & > div {
                width: 100%;
            }
            input[type="date"] {
                border: 1px solid #b3b3b3;
                border-radius: 4px;
                padding: 10px;
                width: 186px;
                height: 36px;
                box-sizing: border-box;
                outline-color: #fa3535;
            }
        }
    }
    &__btn-group {
        display: flex;
        justify-content: end;
        border-top: 1px solid #b3b3b3;
        padding-top: 10px;
        margin-top: 25px;
        button {
            border: 1px solid #a4a4a4;
            border-radius: 4px;
            background: transparent;
            font-family: "Mulish";
            font-weight: 700;
            font-size: 11px;
            color: #4f4f4f;
            max-width: 134px;
            min-width: 134px;
            height: 36px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            &:hover {
                color: #fff;
                background-color: #fa3535;
                border: 1px solid #fa3535;
            }
        }
    }
}

.lsm-popup__content {
    .react-pdf__Page__canvas {
        max-height: 600px;
        width: 100% !important;
        object-fit: contain;
        min-width: 100% !important;
        background: #e0e0e0;
        padding: 30px;
        box-sizing: border-box;
    }
}

.lsm-edit-offers-a,
.lsm-edit-offers-c {
    & > div:first-of-type {
        position: absolute;
        bottom: 65px;
        left: 40%;
        color: #fff;
        font-size: 9px;
        span:first-of-type {
            margin-right: 10px;
        }
    }
    & > div:last-of-type {
        position: absolute;
        bottom: 50px;
        left: 40%;
        color: #fff;
        font-size: 9px;
        span:first-of-type {
            margin-right: 28px;
        }
    }
}

.lsm-edit-offers-b {
    & > div:first-of-type {
        position: absolute;
        bottom: 65px;
        left: 40%;
        color: #fff;
        font-size: 9px;
        span:first-of-type {
            margin-right: 10px;
        }
    }
    & > div:last-of-type {
        position: absolute;
        bottom: 50px;
        left: 40%;
        color: #fff;
        font-size: 9px;
        span:first-of-type {
            margin-right: 27px;
        }
    }
}

.lsm-edit-offers-a,
.lsm-edit-offers-b,
.lsm-edit-offers-c {
    position: relative;

    input {
        position: absolute;
        top: 93%;
        left: 34%;
        background: transparent;
        color: #fff;
        border: 0;
        outline: none;
        font-size: 9px;

        &::placeholder {
            color: #fff;
        }
    }
}

.lsm-edit-offers-b {
    input {
        left: 31%;
    }
}

.lsm-popup__sidebar__input-group--editable {
    flex-basis: 100%;
    h5 {
        font-family: "Mulish";
        font-weight: 700;
        font-size: 16px;
        color: #333333;
        padding-bottom: 7px;
        border-bottom: 1px solid #b3b3b3;
        margin-bottom: 20px;
    }
    & > div:first-of-type {
        margin-bottom: 20px;
    }
    input[type="date"] {
        width: 100% !important;
    }
}

.lsm-edit-deliver-a,
.lsm-edit-deliver-c,
.lsm-edit-deliver-e {
    position: relative;
}

.lsm-edit-deliver-e {
    input {
        top: 37%;
    }
}

.lsm-edit-deliver-a span.brand-assets-c-name {
    position: absolute;
    top: 37%;
    background: transparent;
    color: #fff;
    border: 0;
    outline: none;
    font-size: 36px;
    left: 27%;
    font-weight: 900;
    width: 305px;
    text-align: center;
}

.lsm-edit-deliver-c span.brand-assets-c-name {
    position: absolute;
    top: 37%;
    background: transparent;
    color: #fff;
    border: 0;
    outline: none;
    font-size: 28px;
    left: 27%;
    font-weight: 900;
    width: 305px;
    text-align: center;
}

.lsm-edit-deliver-e span.brand-assets-c-name {
    position: absolute;
    top: 31%;
    background: transparent;
    color: #fff;
    border: 0;
    outline: none;
    font-size: 28px;
    left: 27%;
    font-weight: 900;
    width: 305px;
    text-align: center;
}

.lsm-storeClosesure-1,
.lsm-storeClosesure-2,
.lsm-storeClosesure-3,
.lsm-storeClosesure-4 {
    position: relative;

    span {
        position: absolute;
        top: 32.5%;
        left: 10%;
        background: transparent;
        color: #fff;
        border: 0;
        outline: none;
        font-size: 28px;
        font-weight: bold;
        z-index: 2;
        min-width: 220px;

        &::placeholder {
            color: #fff;
        }
    }
}

.lsm-storeClosesure-2 {
    input {
        left: 7.5%;
    }
}

.lsm-storeClosesure-3,
.lsm-storeClosesure-4 {
    span {
        top: 37%;
        font-size: 30px;
        left: 11%;
    }
}

.lsm-edit-sponsorship-l-a,
.lsm-edit-sponsorship-l-b {
    position: relative;

    span {
        position: absolute;
        top: 65.75%;
        left: 53%;
        background: transparent;
        color: #8d191b;
        border: 0;
        outline: none;
        font-size: 13px;
        font-weight: bold;
        z-index: 2;
        min-width: 330px;

        &::placeholder {
            color: #8d191b;
        }
    }
}

.lsm-edit-sponsorship-l-b {
    span {
        font-size: 11px;
        top: 66%;
    }
}

.lsm-edit-sponsorship-l-c {
    position: relative;

    span {
        position: absolute;
        background: transparent;
        color: #fff;
        border: 0;
        outline: none;
        z-index: 2;
        max-width: 275px;

        top: 57%;
        left: 32.5%;
        width: auto;
        font-size: 34px;
        font-weight: bold;

        &::placeholder {
            color: #fff;
        }
    }
}

.lsm-edit-sponsorship-p-a,
.lsm-edit-sponsorship-p-b {
    position: relative;

    span {
        position: absolute;
        top: 51.5%;
        left: 23%;
        background: transparent;
        color: #8d191b;
        border: 0;
        outline: none;
        font-size: 12px;
        font-weight: bold;
        z-index: 2;
        min-width: 220px;

        &::placeholder {
            color: #8d191b;
        }
    }
}

.lsm-edit-sponsorship-p-b {
    span {
        top: 49%;
        left: 38%;
    }
}

.lsm-edit-sponsorship-p-a {
    span {
        top: 50.55%;
        left: 35%;
    }
}
